import React, { forwardRef } from 'react'

const ContentSlide = forwardRef( ( props,ref ) => {

	const { isActiveSlide, content } = props

	return (
		<li className={`content-slider__slide ${ isActiveSlide ? 'is-active' : '' }`} ref={ref}>
			{ content.children }
		</li>
	)
} );

export default ContentSlide