import React, { useState, useRef, useEffect } from 'react'

import { MEASUREMENTS } from '../../helpers/constants'

import ContentSlide from './ContentSlide'

import ArrowRight from '../../images/svgs/arrow-right.svg'
import ArrowLeft from '../../images/svgs/arrow-left.svg'

export default function ContentSlider( props ) {

	const RESIZE_DEBOUNCE = 150;

	// Uses the reference from the ContentSlide
	const contentSlideRef = useRef()

	const { children, itemsDesktop = 4, itemsTablet = 3, itemsPhablet = 2, itemsMobile = 1 } = props

	const [ slideWidth, setSlideWidth ] = useState()
	const [ activeSlide, setActiveSlide ] = useState(0)
	const [ slidePos, setSlidePos ] = useState(0)
	const [ itemsPerPage, setItemsPerPage ] = useState()

	const slidesItemsLength = children.length
	let itemsLeft = slidesItemsLength - 1;

	useEffect( () => {

		if ( contentSlideRef ) {
			setSlideWidth( contentSlideRef.current.offsetWidth )
		}

		if ( window.innerWidth <= MEASUREMENTS.MOBILE ) {
			setItemsPerPage( itemsMobile )
		} else if ( window.innerWidth <= MEASUREMENTS.PHABLET ) {
			setItemsPerPage( itemsPhablet )
		} else if ( window.innerWidth <= MEASUREMENTS.TABLET ) {
			setItemsPerPage( itemsTablet )
		} else {
			setItemsPerPage( itemsDesktop )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setSlideWidth( contentSlideRef.current.offsetWidth ), RESIZE_DEBOUNCE );
			// on resize reset slider
			setActiveSlide( 0 )
			setSlidePos( 0 )

			if ( window.innerWidth <= MEASUREMENTS.MOBILE ) {
				setItemsPerPage( itemsMobile )
			} else if ( window.innerWidth <= MEASUREMENTS.PHABLET ) {
				setItemsPerPage( itemsPhablet )
			} else if ( window.innerWidth <= MEASUREMENTS.TABLET ) {
				setItemsPerPage( itemsTablet )
			} else {
				setItemsPerPage( itemsDesktop )
			}
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};

	}, [ itemsDesktop, itemsTablet, itemsPhablet, itemsMobile ] );

	const scrollNext = () => {
		let newSlide = activeSlide + itemsPerPage > slidesItemsLength ? slidesItemsLength : activeSlide + itemsPerPage;
		
		itemsLeft = slidesItemsLength - newSlide ;

		if ( itemsLeft < itemsPerPage ) {
			newSlide = newSlide - itemsPerPage + itemsLeft;
		}
		
		setActiveSlide( newSlide )
		setSlidePos( slideWidth * newSlide );
	}

	const scrollPrev = () => {
		const newSlide = activeSlide <= itemsPerPage ? 0 : activeSlide - itemsPerPage;

		itemsLeft = slidesItemsLength - newSlide ;

		setActiveSlide( newSlide )
		setSlidePos( slideWidth * newSlide );
	}

	/**
	 * Checks if the slide is in the current view, if so return true
	 * @param {Number} slideIndex
	 */
	const isSlideActive = ( slideIndex ) => {
		let isActive = false;

		if ( slideIndex < activeSlide + itemsPerPage && slideIndex >= activeSlide ) {
			isActive = true;
		}

		return isActive;
	}

	return (
		<div className="content-slider">
			<div className="content-slider__container">
				<div className="content-slider__wrapper">
					<ul
						className={`content-slider__inner-wrapper block-list-${itemsDesktop} block-list-${itemsTablet}-tablet block-list-${itemsPhablet}-phablet block-list-${itemsMobile}-mobile`}
						style={{ left: `-${ slidePos }px` }}>
						{ children.map( ( child, i ) => (
							<ContentSlide
								content={ child.props }
								key={ i }
								ref={ contentSlideRef }
								isActiveSlide={ isSlideActive( i ) } />
						) ) }
					</ul>
				</div>
			</div>

			<ArrowLeft
				className={`content-slider__control content-slider__control--prev ${ !activeSlide ? 'disabled' : '' }`}
				onClick={ () => scrollPrev() } />
			<ArrowRight
				className={`content-slider__control content-slider__control--next ${ slidesItemsLength - activeSlide === itemsPerPage ? 'disabled' : '' }`}
				onClick={ () => scrollNext() } />
		</div>
	)
}