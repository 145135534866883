import React, { forwardRef } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const ProductThumbnail = forwardRef( ( props,ref ) => {

	const { isSelected, image, index, handleThumbnailClick } = props

	return (
		<li className="product-images__slide" ref={ ref }>
			<div className="product-images__thumbnail-wrapper">
				<button
					className={`product-images__thumbnail-container ${ isSelected ? 'active' : '' }`}
					onClick={ () => handleThumbnailClick( index, image ) }>
					<GatsbyImage
						image={image.imageFile.childImageSharp.gatsbyImageData}
						className="product-images__thumbnail-image"
						objectFit="contain"
						loading="lazy"
						alt="" />
				</button>
			</div>
		</li>
	)
} )

export default ProductThumbnail