import React, { useState, useContext, useEffect } from "react"
import { graphql } from "gatsby"

import ReactMarkdown from "react-markdown"
import removeMd from "remove-markdown"

import { CartContext } from '../context/CartContext'
import { formatPrice } from '../helpers/currency'
import { GTAddToCart, GTViewItem } from '../helpers/gtagEvents'

import CartIcon from '../images/svgs/cart-icon.svg'
import ArrowDown from '../images/svgs/arrow-down.svg'
import PlusIcon from '../images/svgs/plus-icon.svg'
import MinusIcon from '../images/svgs/minus-icon.svg'

import ProductImages from '../components/ProductImages/ProductImages'
import RelatedProducts from '../components/RelatedProducts/RelatedProducts'
import Seo from '../components/SEO/Seo'

const ProductTemplate = ({ data: { strapi: { product } }, location }) => {

	const [ quantity, setQuantity ] = useState( 1 )
	const { addToCart } = useContext( CartContext )
	const [ framed, setFramed ] = useState( false )

	const productInStock = product.quantityInStock ? true : false;

	// Remove markdown from description then remove any line breaks
	const metaDescription = product.description ? removeMd( product.description ).replace(/[\r\n]+/gm, " ").substring(0, 160) : null
	const metaImage = product.images ? {
		src: product.images[0].imageFile.childImageSharp.gatsbyImageData.images.fallback.src,
		height: 500,
		width: 500,
	} : null

	const addToBag = ( product, quantity ) => {
		const productCopy = { ...product }
		productCopy.framed = framed
		productCopy.price = framed ? productCopy.priceWithFrame : productCopy.price

		addToCart( productCopy, quantity )
		GTAddToCart( productCopy, quantity ) // Trigger gtag event
	}

	const incrementQuantity = ( increment ) => {
		const incrementedQuantity = quantity + increment;

		if ( incrementedQuantity ) {
			setQuantity( incrementedQuantity );
		}
	}

	useEffect(() => {
		GTViewItem( product )
	}, [ product ] )

	const generateProductSchema = product => {
		// Remove markdown from description then remove any line breaks
		const strippedDescription = removeMd( product?.description ).replace(/[\r\n]+/gm, " ")

		let schema = {
			"@context": "https://schema.org/",
			"@type": "Product",
			"name": `${ product.name }`,
			"image": `${ process.env.SITE_URL }${ metaImage?.src }`,
			"description": `${ strippedDescription }`,
			"offers": {
				"@type": "Offer",
				"url": `${ process.env.SITE_URL }${ location.pathname }`,
				"priceCurrency": "GBP",
				"price": `${ product.price }`,
			}
		}

		if ( product?.quantityInStock ) {
			schema.offers[ 'availability' ] = 'https://schema.org/InStock'
		} else {
			schema.offers[ 'availability' ] = 'https://schema.org/OutOfStock'
		}

		return JSON.stringify( schema )
	}

	return (
		<>
			<Seo
				title={ product.name }
				description={ metaDescription }
				image={ metaImage }
				product={ true }
				productPrice={ product.price }
				pathname={ location.pathname }>

				<script type="application/ld+json">
					{ generateProductSchema( product ) }
				</script>
			</Seo>

			<div className="wrapper">

				<div className={`product-template ${ !productInStock ? 'sold-out' : ''}`}>

					<div className="product-template__main-section">
						<div className="product-template__image">
							<ProductImages images={ product.images } productInStock={ product.quantityInStock } />
						</div>

						<div className="product-template__info">
							<h1 className="product-template__name">{ product.name }</h1>
							<span className="product-template__artist">by Daisy Carrick-Smith</span>

							<div className="product-template__meta-info">{ product.metaDescription }</div>

							<div className="product-template__inputs-container">
								<div className={`product-template__dropdown-container ${ !product.quantityInStock ? 'disabled' : '' }`}>
									<select
										className="product-template__dropdown"
										onChange={ e => setFramed( e.target.value === "true")}
										defaultValue="false"
										disabled={ product.priceWithFrame ? false : 'disabled' }>
										{ !product.quantityInStock ? (
											<option value="false">Out of stock</option>
										) : (
											<>
												<option value="false">{ formatPrice( product.price ) } { formatPrice( product.priceWithFrame ) ? "unframed" : ''}</option>
												{ product.priceWithFrame && <option value="true" >{ formatPrice( product.priceWithFrame ) } framed</option> }
											</>
										) }
									</select>
									<ArrowDown className={`product-template__dropdown-arrow ${ !product.priceWithFrame ? 'disabled' : '' }`}/>
								</div>
								<div className={`product-template__quantity-container ${ !product.quantityInStock ? 'disabled' : '' }`}>
									<button className={`product-template__quantity-trigger ${ quantity === 1 || !productInStock ? 'disabled' : '' }`} onClick={ () => incrementQuantity( -1 ) }>
										<MinusIcon className="product-template__quantity-icon" />
									</button>
									<div className="product-template__quantity-input">
										{ quantity }
									</div>
									<button className={`product-template__quantity-trigger ${ quantity === product.quantityInStock || !productInStock ? 'disabled' : '' }`} onClick={ () => incrementQuantity( 1 ) }>
										<PlusIcon className="product-template__quantity-icon" />
									</button>
								</div>
							</div>
							<button
								className={`product-template__button ${ !productInStock ? 'disabled' : '' }`}
								onClick={ () => addToBag( product, quantity ) }>
									ADD TO ART CART
								<CartIcon className="product-template__button-icon" />
							</button>
						</div>
					</div>
					<div className="product-template__product-info">
						<ReactMarkdown children={ product.description } className="product-template__description" />
					</div>
				</div>
			</div>
			<RelatedProducts category={ product.category.name } id={ product.id } /> 
		</>
	)
}

export default ProductTemplate

export const query = graphql`
	query ProductTemplateQuery($id: ID!) {
		strapi {
			product(id: $id) {
				id
				name
				price
				priceWithFrame
				description
				quantityInStock
				slug
				medium
				metaDescription
				dimensions
				images {
					id
					url
					imageFile {
						childImageSharp {
							gatsbyImageData(width: 1728)
						}
					}
				}
				category {
					name
				}
			}
		}
	}
`