import React, { useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import PlusIcon from '../../images/svgs/plus-icon.svg'
import ArrowRight from '../../images/svgs/arrow-right.svg'
import ArrowLeft from '../../images/svgs/arrow-left.svg'

export default function ProductModal ( props ) {

	const { state, modalActive, toggleImageModal, scrollNext, scrollPrev, totalImages } = props

	useEffect(() => {

		// If the user presses the escape key, close the modal
		const handleUserKeyPress = e => {

			if ( e.keyCode === 27 && modalActive ) {
				toggleImageModal()
			}
		}

		window.addEventListener( 'keydown', handleUserKeyPress )

		return () => {
			window.removeEventListener( 'keydown', handleUserKeyPress )
		}

	}, [ modalActive, toggleImageModal ])

	return (
		<div className={`product-images__modal ${ modalActive ? 'active' : '' }`}>
			<div className="product-images__modal-container">
				<PlusIcon className="product-images__modal-close" onClick={ () => toggleImageModal() } />
				<GatsbyImage
					image={state.mainImage.imageFile.childImageSharp.gatsbyImageData}
					className="product-images__modal-image"
					objectFit="contain"
					loading="lazy"
					alt=""
				/>

				<ArrowLeft
					className={`product-images__modal-button product-images__modal-button--prev ${ !state.currentIndex ? 'disabled' : '' }`}
					onClick={ () => scrollPrev() }
					/>

				<ArrowRight
					className={`product-images__modal-button product-images__modal-button--next ${ state.currentIndex + 1 === totalImages ? 'disabled' : '' }`}
					onClick={ () => scrollNext() }
				/>
			</div>
		</div>
	)
}