import React, { useState, useEffect } from "react"
import { useQuery, gql } from '@apollo/client'

import ContentSlider from '../ContentSlider/ContentSlider'
import ProductCard from '../ProductCard/ProductCard'
import { getFluidImage } from '../../helpers/apollo'

import Heading from "../Heading/Heading"

const RelatedProducts = ( props ) => {

	const { category, id } = props

	const limit = 8 // number of products wanted to be shown
	const max = 500

	const where = { id_nin: [ id ], category: { name: category }, quantityInStock_nin: 0 } // first get related products by tag
	const [ variables, setVarialbles ] = useState({ limit: limit, where: where })
	const [ state, setState ] = useState({ products: [] })
	const [ ids, setIds ] = useState([ id ])

	const productQuery = gql`
		query getRelatedProducts ( $limit: Int, $where: JSON ) {
			products ( limit: $limit, where: $where ) {
				id
				name
				price
				slug
				medium
				dimensions
				quantityInStock
				images {
					url
					formats
					width
					height
				}
			}
		}
	`
	
	let { error, data } = useQuery( productQuery,
		{
			variables: {
				...variables
			},
			fetchPolicy: "cache-and-network"
		}
	);

	useEffect(() => {

		if ( data ) {
			let parsedData = JSON.parse( JSON.stringify( data ) )
			let updatedProducts = state.products

			parsedData.products.forEach( product => {

				if ( ids.includes( product.id ) ) return

				product.images[0].imageFile = { childImageSharp: { gatsbyImageData : getFluidImage( product, max, 0 ) } }

				if ( product.images.length > 1 ) {
					product.images[1].imageFile = { childImageSharp: { gatsbyImageData : getFluidImage( product, max, 1 ) } }
				}

				ids.push( product.id )
				updatedProducts.push( product )
			})

			if ( updatedProducts.length < limit ) {
				if ( variables.where.category ){
					setVarialbles( {limit: limit, where: { id_nin: ids, quantityInStock_nin: 0 }})
				}
			} else {
				updatedProducts.length = limit
			}

			setState({ products: updatedProducts })
			setIds( ids )
		}
	}, [ data, ids, state.products, variables ])

	if ( error ) return null;

	return (
		<>
			{ state && !!state.products.length &&
				<>
					<Heading heading="Other Products" subHeading={ true } />
					<div className="related-products wrapper">
						<ContentSlider itemsDesktop={ 4 } itemsTablet={ 3 } itemsMobile={ 1 }>
							{ state.products.map( ( node , i ) => (
								<React.Fragment key={ i }>
									<ProductCard product={ node } showCartButton={ false } />
								</React.Fragment>
							) ) }
						</ContentSlider>
					</div>
				</>
			}
		</>
	)
}

export default RelatedProducts