import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { formatPrice } from '../../helpers/currency'
import { fromProductSlugToUrl } from '../../helpers/products'

const ProductCard = ({ product }) => {
	return (
		<div className="product-card">
			<div className="product-card__container">
				<Link to={fromProductSlugToUrl( product.slug )} className="product-card__item-link">
					<div className="product-card__item-image-wrapper">
						<div className="product-card__item-image-container">
							{ product.images.length > 1 ? (
								<>
									<GatsbyImage
										image={product.images[0].imageFile.childImageSharp.gatsbyImageData}
										className={`product-card__item-image product-card__item-image--top ${!product.quantityInStock ? 'disabled' : ''}`}
										objectFit="contain"
										loading="lazy"
										alt={product.name}
									/>
									<GatsbyImage
										image={product.images[1].imageFile.childImageSharp.gatsbyImageData}
										className={`product-card__item-image product-card__item-image--bottom ${!product.quantityInStock ? 'disabled' : ''}`}
										objectFit="contain"
										loading="lazy"
										alt={product.name}
									/>
								</>
							) : (
								<GatsbyImage
									image={product.images[0].imageFile.childImageSharp.gatsbyImageData}
									className={`product-card__item-image product-card__item-image--zoom ${ !product.quantityInStock ? 'disabled' : ''}`}
									objectFit="contain"
									loading="lazy"
									alt={product.name}
								/>
							) }
							{ !product.quantityInStock &&
								<span className="product-card__sold">SOLD</span>
							}
						</div>
					</div>
					<div className="product-card__item-meta-wrapper">
						<h3 className="product-card__item-name">{ product.name }</h3>
						{ !!product.quantityInStock && <span className="product-card__item-price">{ formatPrice( product.price ) }</span> }
					</div>
				</Link>
			</div>
		</div>
	)
}

ProductCard.propTypes = {
	product: PropTypes.object
};

export default ProductCard;