export const aspectRatio = ( product, index ) => {
	return product.images[ index ].width / product.images[ index ].height
}

export const srcSet = ( product, index ) => {
	let srcset = []

	for ( let key in product.images[ index ].formats ) {
		const srcString = `${ product.images[ index ].formats[ key ].url } ${ product.images[ index ].formats[ key ].width }w`
		const src = srcString
		srcset.push( src )
	}

	// If images are smaller than 500px, strapi doesn't generate a 'small' format, this is used in the product cards
	// therefore if the small format doesn't exist, we use the initial image
	if ( srcset.length === 1 && !product.images[ index ].formats.small ) {
		const srcString = `${ product.images[ index ].url } 500w`
		const src = srcString
		srcset.push( src )
	}

	return srcset.join()
}

export const getFluidImage = ( product, maxWidth, index ) => {
	const result = {}

	result.images = getImages( product, maxWidth, index )
	result.width = maxWidth // Setting the width

	return result
}

export const getImages = ( product, maxWidth, index ) => {

	const images = {
		fallback: {
			sizes: `(max-width: ${ maxWidth }px) 100vw, ${ maxWidth }px`,
			src: product.images[ index ].url,
			srcSet: srcSet( product, index ),
		},
		sources: [ {
			sizes: `(max-width: ${ maxWidth }px) 100vw, ${ maxWidth }px`,
			srcSet: srcSet( product, index ),
			type: 'image/webp'
		} ]
	}

	return images
}